import React, { Component } from 'react';
import { Web3Instance } from './web3Instance';
import { isConnected } from './mda';
import { contractloterry } from './lotteryabi';
import { Fade, Slide } from "react-reveal";
import Menu from './menu';
import pantalla from "./images/continuar1.png";
import huevos from "./images/huevo.png";
import armas from "./images/mayal.png";
import oromatic from "./images/currency.png";
import frijoler from "./images/frijoles-rojos.png";
import bolsadinero from "./images/bolsa-de-dinero.png";
import cereales from "./images/lata.png";
import EggD from "./images/eggd.png";
import caballere from "./images/caballero.png";
import Tienda from "./images/desplazarse.png";
import vault from "./images/cofre-del-tesoro.png";
import twitter from "./images/gorjeo.png";
import telegram from "./images/telegrama.png";
import github from "./images/github.png";
import insta from "./images/instagram.png";
import face from "./images/facebook.png";
import whitherpaper from "./images/book.png";
import "./content.css";
import whitepapper1 from "./whiterpapper.pdf";

export default class LOTTERY extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        account: null,
        web3: null,
        provider: null,
        contractBalance1: null,
        balance1: null,
        contractlastWinners: null,
        lastW: null,
        contractlastWinnersa:null,
        lastWa: null,
        contractRtickets: null,
        Rtickets: null,
        tickets: null,
        contracttickets: null,
        contractseconds1: null,
        contractdays1: null,
        days1: null,
        contracthours1: null,
        seconds1: null,
        hours1: null,
        minutes1: null,
        contractminutes1: null,
        buttonText: isConnected() ? "Connected" : "Connect Wallet",
        loterryAddress: "0x271FA80B2F320045C3464EbA56DA20dA94d6ee7f",
        inputValue: "",
        referral89Address: "",
        walletAddressinput: "",
        numberOfTickets: "",
        referral89Link: "" 
      };
  
      this.handleConnectClick = this.handleConnectClick.bind(this);
      this.handleDisconnectClick = this.handleDisconnectClick.bind(this);
      this.handleGetReferral98Link =  this.handleGetReferral98Link.bind(this);
      this.handleBuyticketClick = this.handleBuyticketClick.bind(this);
      this.handlegetContractpool = this.handlegetContractpool.bind(this);
      this.handlegetduration = this.handlegetduration.bind(this);
      this.handletickets = this.handletickets.bind(this);
    this.handlewithdrawClick = this.handlewithdrawClick.bind(this);
      this.handlegetRemainingTickets =  this.handlegetRemainingTickets.bind(this);
    this.handlegetlastWinner =  this.handlegetlastWinner.bind(this);
    this.handlegetlastWinnerAmount =  this.handlegetlastWinnerAmount.bind(this);
      this.displayedAccount = this.displayedAccount.bind(this);
      this.handleInputChange = (event) => this.setState({ numberOfTickets: event.target.value });
      this.handleInputChange12 = (event) => {
        const input = event.target.value;
        const walletAddressinput = input.replace(/[^0-9a-fA-Fx]/g, '').substring(0, 42); // Elimina todo lo que no sea letra, número o "x"
        this.setState({ walletAddressinput });
      }
      this.load = this.load.bind(this);

   if (typeof window.ethereum !== 'undefined') {
      this.checkAccountChange = this.checkAccountChange.bind(this);
      window.ethereum.on('accountsChanged', this.checkAccountChange);
    }

  }
  checkAccountChange(accounts) {
    if (this.state.account !== accounts[0]) {
      this.handleDisconnectClick();
      this.handleGetReferral98Link();
    }
    this.state = this.InitialState
  }

  async handleGetReferral98Link() {
    try {
        await window.ethereum.enable();
        const web3 = new Web3(window.ethereum);
        const walletaddress = this.state.account;
        const queryParams = new URLSearchParams(window.location.search);
        const referrer = queryParams.get('ref');
        const referral89Address = referrer || walletaddress;

        let referral89Link;
        if (referrer) {
            // Si hay un valor 'ref' en los parámetros de la URL, usa ese valor en el enlace
            referral89Link = `https://epicbeans.io/LOTTERY?referral=${referrer}`;
        } else {
            // Si no hay valor 'ref', usa la dirección de la billetera actual en el enlace
            referral89Link = `https://epicbeans.io/LOTTERY?referral=${walletaddress}`;
        }

        this.setState({ referral89Link });

        return { referral89Address, referral89Link, walletaddress, referrer };
    } catch (error) {
        console.log(error);
    }
}


async handlegetContractpool() {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      alert("Please install MetaMask to use this feature");
      return;
    }
    const contractInstance = new this.state.web3.eth.Contract(contractloterry , this.state.loterryAddress);
  
    try {
      const balance1 = await contractInstance.methods.getBalance().call({ from: this.state.account });
      const balanceInEther = this.state.web3.utils.fromWei(balance1, 'ether'); // convert wei to ether
      console.log(balanceInEther);
      this.setState({ contractBalance1: balanceInEther });
    } catch (error) {
      console.error(error);
    }
  }
  async handlegetlastWinner() {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      alert("Please install MetaMask to use this feature");
      return;
    }
    const contractInstance = new this.state.web3.eth.Contract(contractloterry , this.state.loterryAddress);
  
    try {
      const lastW = await contractInstance.methods.lastWinner().call({ from: this.state.account });
      console.log(lastW.slice(0,4) + "..." + lastW.slice(-4));
      this.setState({ contractlastWinners: lastW.slice(0,4) + "..." + lastW.slice(-4) });
    } catch (error) {
      console.error(error);
    }
  }
  async handlegetRemainingTickets() {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      alert("Please install MetaMask to use this feature");
      return;
    }
    const contractInstance = new this.state.web3.eth.Contract(contractloterry , this.state.loterryAddress);
  
    try {
      const Rtickets = await contractInstance.methods.RemainingTickets().call({ from: this.state.account });
      console.log(Rtickets);
      this.setState({ contractRtickets: Rtickets });
    } catch (error) {
      console.error(error);
    }
  }

  async handlegetduration() {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      alert("Please install MetaMask to use this feature");
      return;
    }
  
    const contractInstance = new this.state.web3.eth.Contract(contractloterry , this.state.loterryAddress);
  
    try {
      const walletaddress = this.state.account;
      const seconds1 = await contractInstance.methods.getTimeLeft().call({ from: this.state.account });
      console.log(seconds1);
  
      // convert seconds to days, hours and minutes
      const days1 = Math.floor(seconds1 / (3600 * 24));
      const hours1 = Math.floor((seconds1 % (3600 * 24)) / 3600);
      const minutes1 = Math.floor((seconds1 % 3600) / 60);
      console.log(days1, hours1, minutes1);
  
      this.setState({ contractseconds1: seconds1,  contractdays1: days1, contracthours1: hours1, contractminutes1: minutes1 });
    } catch (error) {
      console.error(error);
    }
  }

  async handlegetlastWinnerAmount() {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      alert("Please install MetaMask to use this feature");
      return;
    }
    const contractInstance = new this.state.web3.eth.Contract(contractloterry , this.state.loterryAddress);
  
    try {
      const lastWa = await contractInstance.methods.lastWinnerAmount().call({ from: this.state.account });
      const balanceInEtherWA = this.state.web3.utils.fromWei(lastWa, 'ether'); // convert wei to ether
      console.log(balanceInEtherWA);
      this.setState({ contractlastWinnersa: balanceInEtherWA });
    } catch (error) {
      console.error(error);
    }
  }
  async handletickets() {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      alert("Please install MetaMask to use this feature");
      return;
    }
  
    const contractInstance = new this.state.web3.eth.Contract(contractloterry , this.state.loterryAddress);
  
    try {
      const walletaddress = this.state.account;
      const tickets = await contractInstance.methods.getTicketsForAddress(walletaddress).call({ from: walletaddress });
      console.log(tickets);
      this.setState({ contracttickets: tickets });
    } catch (error) {
      console.error(error);
    }
  }
  async handleBuyticketClick() {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
        alert("Please install MetaMask to use this feature");
        return;
    }

    const contractInstance = new this.state.web3.eth.Contract(contractloterry, this.state.loterryAddress);

    const balance = await this.state.web3.eth.getBalance(this.state.account);
    
    // Set a fixed amount of 0.001 ether per ticket
    const ticketPrice = "0.002";

    // Calculate the total amount to send
    const totalAmountToSend = (this.state.numberOfTickets * ticketPrice).toString();

    const amountToSend = totalAmountToSend === "" || Number(totalAmountToSend) < Number(ticketPrice)
        ? "0"
        : this.state.web3.utils.toBN(this.state.web3.utils.toWei(totalAmountToSend)).toString();

    const gasPrice = await this.state.web3.eth.getGasPrice();
    const gasLimit = 300000;

    // Use the referral wallet address from the state
    const referralAddressLottery = this.state.walletAddressinput;

    const transactionParameters = {
      from: this.state.account,
        value: amountToSend,
        gasPrice,
        gas: gasLimit,
    };
    try {
      const transactionHash = await contractInstance.methods.BuyTickets(referralAddressLottery)
        .send(transactionParameters);
      console.log(`Transaction hash: ${transactionHash}`);
    } catch (error) {
      console.error(error);
    }
}

 async handlewithdrawClick() {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
        alert("Please install MetaMask to use this feature");
        return;
      }
    
      const contractInstance = new this.state.web3.eth.Contract(contractloterry , this.state.loterryAddress);
    
      try {
        const balance = await this.state.web3.eth.getBalance(this.state.account);
        const gasPrice = await this.state.web3.eth.getGasPrice();
        const gasLimit = 300000;
        const transactionParameters = {
          from: this.state.account,
          to: this.state.loterryAddress,
          gasPrice,
          gas: gasLimit,
        };
    
        const transactionHash = await contractInstance.methods.WithdrawWinnings().send(transactionParameters);
        console.log(`Transaction hash: ${transactionHash}`);
      } catch (error) {
        console.error(error);
      }
    }
async componentDidMount() {
  this._web3Instance = await new Web3Instance().init();
  this.checkConnection();
  if (isConnected()) {
    this.load();
    this.handleGetReferral98Link();
    this.checkAccountChange([this.state.account]);
  }
  try {
    const { referral89Link } = await this.handleGetReferral98Link();
    this.setState({ referral89Link });
  } catch (error) {
    console.log(error);
  }

  // Agregar el siguiente código sin borrar los anteriores
  if (typeof window.ethereum !== 'undefined') {
    this.checkAccountChange = this.checkAccountChange.bind(this);
    window.ethereum.on('accountsChanged', this.checkAccountChange);
  }

  if (isConnected()) {
    const balance1 = await this.handlegetContractpool();
    this.setState({ balance1 });
    const Rtickets = await this.handlegetRemainingTickets();
    this.setState({ Rtickets });
    const tickets = await this.handletickets()
    this.setState({ tickets });
    const lastW = await this.handlegetlastWinner();
    this.setState({ lastW });
    const lastWa = await this.handlegetlastWinnerAmount();
    this.setState({ lastWa });
    const seconds1 = await this.handlegetduration();
    this.setState({ seconds1 });
    const days1 = await this.handlegetduration();
    this.setState({ days1 });
    const hours1 = await this.handlegetduration();
    this.setState({ hours1 });
    const minutes1 = await this.handlegetduration();
    this.setState({ minutes1 });  
  }
}

  async checkConnection() {
    if (isConnected()) {
      await this._web3Instance.connect();
      this.load();
    }
  }

  async load() {
    this.setState({
      account: this._web3Instance.getAccount(),
      web3: this._web3Instance.getWeb3(),
      provider: this._web3Instance.getProvider(),
      buttonText: this.displayedAccount(),
    });
  }

  async handleConnectClick() {
    if (!isConnected()) {
      await this._web3Instance.connect();
      this.load();
    }
  }

  async handleDisconnectClick() {
    if (isConnected()) {
    await this._web3Instance.disconnect();
    this.setState({
    account: null,
    web3: null,
    provider: null,
    buttonText: "Connect Wallet"
    });
    }
    }
  displayedAccount() {
    const account = this._web3Instance.getAccount();
    if (account) {
      return account.slice(0, 4) + "..." + account.slice(-4);
    } else {
      return "Connect Wallet";
    }
  }
  resetState() {
    this.setState(this.InitialState)
}
render() {
    return (
      <div>
        <nav className="nav">
          <div className="connect-wallet no-select">
            <button onClick={isConnected() ? this.handleDisconnectClick : this.handleConnectClick}>
              {this.state.buttonText}
            </button>
          </div>
          <Menu />
        </nav>
        <div className="perraputainfame">
  <div className="perraputainfame-track">
    <div className="slide-container">
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
    </div>
  </div>
</div>
        <div className="pitodeperronegro">
        <div className="content">
                  <div className="container">
                    <Fade top>
                    <div className="Pantalla no-select">
                    <img src={pantalla} alt="Pantalla" />
                    </div>
                    </Fade>
                    <div className="text-container">
                        <div>
                      <div className="info no-select">
                     <div className="balance">
                       <Slide left>
                     <div className="balance-contract"><img src={bolsadinero} className="bolsaD" alt="Balance" /> Pool: {this.state.contractBalance1} BNB</div>
                     </Slide>
                     <Slide right>
                     <div className="rewards-contract"><img src={oromatic} className="bolsaD" alt="Balance" /> LastWiner: {this.state.contractlastWinners} </div>
                     </Slide>
                     <Slide left>
                     <div className="rewards-contract"><img src={frijoler} className="bolsaD" alt="Balance" /> Last WinerAmount: {this.state.contractlastWinnersa} BNB </div>
                     </Slide>
                     <Slide right>
                     <div className="hasInvested-contract"><img src={cereales} className="bolsaD" alt="Balance" /> Number of unsold tickets: {this.state.contractRtickets}</div>
                     </Slide>
                     <Slide left>
                     <div className="bnbTvl-contract"><img src={EggD} className="bolsaD" alt="Balance" /> Your Tickets: {this.state.contracttickets}</div>
                     </Slide>
                     <Slide right>
                     <div className="dailyReward-contract"><img src={caballere} className="bolsaD" alt="Balance" /> Offer period: days {this.state.contractdays1}, minutes {this.state.contractminutes1}, seconds {this.state.contractseconds1} </div>
                     </Slide>
                </div>
                  </div>
                          <div className="form-container">
                          <input className="input" type="text" maxLength="42" placeholder="Enter Referral Wallet" onChange={this.handleInputChange12} />
                            <Fade bottom>
                            <p className="min-max-text no-select"><svg className="bolsaD" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  viewBox="0 0 16 16">
  <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
  <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2z"/>
</svg> Enter Referral Wallet</p>
                            <br></br>
                            </Fade>
                            <input className="input" type="number" min="1" max="10000" step="1" placeholder="How many tickets do you want?" onChange={this.handleInputChange} />
                            <Fade top>
                            <p className="min-max-text no-select"><img src={Tienda} className="bolsaD" alt="Balance" /> The ticket price is 0.002 BNB, and there are only 500 tickets available.</p>
                            </Fade>
                          </div>
                          <div className="button-container no-select">
                            <div className="staking">
                              <button onClick={this.handleBuyticketClick}><img src={armas} className="bolsaD" alt="Balance" /> Buy Tickets</button>
                            </div>
                            <div className="withdraw">
                              <button onClick={this.handlewithdrawClick}><img src={huevos} className="bolsaD" alt="Balance" /> Withdraw</button>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div> 
                <div  className="conteinerinicio no-select">
                <div className="fondito">
  <div  className="conteinerinfotoken243">
  <h1 className="letrastokenff999 no-select">FEES $BNB</h1>
  <Fade top> 
    <div className="feesporque"> 
      <h3 className="slaves no-select">
      If no one invited you to the lottery, you have to provide your wallet address to receive a cashback. In case someone invited you, you should use their wallet address if you wish to compensate them with a very low commission for recommending EPIC BEANS LOTTERY. Even if time runs out, the lottery will not take place until all tickets are sold
      </h3>
    </div>
    </Fade>
    </div>
    </div>
    </div>
    </div>
      <div className="footer no-select">
          <div className="github">
            <a href="https://github.com" target="_blank">
            <img src={github} className="github" alt="github" />
            </a>
          </div>
          <div className="telegrama">
            <a href="https://t.me/epicbeans" target="_blank">
            <img src={telegram} className="telegrama" alt="telegrama" />
            </a>
          </div>
          <div className="telegrama">
            <a href="https://instagram.com/epicbeansminer?igshid=NGVhN2U2NjQ0Yg==" target="_blank">
            <img src={insta} className="telegrama" alt="telegrama" />
            </a>
          </div>
          <div className="telegrama">
            <a href="https://www.facebook.com/profile.php?id=61553179738387&mibextid=ZbWKwL
" target="_blank">
            <img src={face} className="telegrama" alt="telegrama" />
            </a>
          </div>
          <div className="whitherpaper">
            <a href={whitepapper1} target="_blank">
            <img src={whitherpaper} className="whitherpaper" alt="whitherpaper" />
            </a>
          </div>
          <div className="twitter">
            <a href="https://x.com/EPICBEANS_MINER?t=ksBR2eP2O4_Jl_-sHGd2Tg&s=09" target="_blank">
            <img src={twitter} className="twitter" alt="twitter" />
            </a>
          </div>
          <div className="contrato">
            <a href="https://bscscan.com/address/0x271FA80B2F320045C3464EbA56DA20dA94d6ee7f" target="_blank">
            <img src={vault} className="contrato" alt="contrato" />
            </a>
          </div>
        </div>
      </div>
    );  
  }
}