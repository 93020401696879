import React, { useEffect } from 'react';
import { Fade } from "react-reveal";
import './Roadmap.css';

const Roadmap = () => {
    const milestones = [
        { year: '2023', description: 'Create the token', completed: true },
        { year: '2023', description: 'Token Launch', completed: true },
        { year: '2023', description: 'List the token on PancakeSwap', completed: true },
        { year: '2023', description: 'Create a YouTube campaign with a challenging community', completed: true },
        { year: '2023', description: 'Massive airdrop', completed: true },
        { year: '2023', description: 'List the project on Dexscreener', completed: true },
        { year: '2023', description: 'Create a web3 application', completed: true },
        { year: '2024', description: 'Smart Contract Audit' },
        { year: '2024', description: 'Create a community' },
        { year: '2024', description: 'List on bsc chain' },
        { year: '2024', description: 'List on CoinMarket Cap' },
        { year: '2024', description: 'List on Coingecko' },
        { year: '2024', description: 'List on an exchange' },
        { year: '2025', description: 'Create a web3 shitpost' },
        { year: '2026', description: 'The best MEMECOIN $EBT' },
      ];

  useEffect(() => {
    const animateMilestones = () => {
      const milestoneElements = document.querySelectorAll('.milestone');

      milestoneElements.forEach((milestone, index) => {
        const delay = index * 200;
        setTimeout(() => {
          milestone.classList.add('animate');
        }, delay);
      });
    };

    animateMilestones();
  }, []);

  return (
    <div className="roadmap-container">
      <h2 className="putaroadmap no-select">Roadmap</h2>
      <div className="roadmap">
        <div className="vertical-line"></div>
        <div className="milestones">
          {milestones.map((milestone, index) => (
            <div
              key={index}
              className={`milestone ${milestone.completed ? 'completed' : ''}`}
              data-year={milestone.year}
            >
              <div className="vertical-line"></div>
              <div className="circle"></div>
              <div className="content0909">
                <h3 className="no-select">{milestone.year}</h3>
                <p className="no-select">{milestone.description}</p>
                {milestone.completed && (
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-patch-check-fill" viewBox="0 0 16 16">
                    <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"/>
                  </svg>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
