export const contractAbi8 = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "adr",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "bnbToEat",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "beansBeforeFee",
				"type": "uint256"
			}
		],
		"name": "EmitAte",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "adr",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "ref",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "beansFrom",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "beansTo",
				"type": "uint256"
			}
		],
		"name": "EmitBaked",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "adr",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "ref",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "bnbamount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "beansFrom",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "beansTo",
				"type": "uint256"
			}
		],
		"name": "EmitBoughtBeans",
		"type": "event"
	},
	{
		"stateMutability": "payable",
		"type": "fallback"
	},
	{
		"inputs": [],
		"name": "EBT_TOKEN",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokens",
				"type": "uint256"
			}
		],
		"name": "airdrop",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "bake",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "adr",
				"type": "address"
			}
		],
		"name": "bnbRewards",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "adr",
				"type": "address"
			}
		],
		"name": "bnbTvl",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "ref",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "buyBEANS",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address payable",
				"name": "_withdrawal",
				"type": "address"
			}
		],
		"name": "clearETH",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "adr",
				"type": "address"
			}
		],
		"name": "dailyReward",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "eat",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "adr",
				"type": "address"
			}
		],
		"name": "hasBeanTaxed",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "adr",
				"type": "address"
			}
		],
		"name": "hasInvested",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "initializeContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "invested",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "adr",
				"type": "address"
			}
		],
		"name": "maxPayout",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "adr",
				"type": "address"
			}
		],
		"name": "maxPayoutReached",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "adr",
				"type": "address"
			}
		],
		"name": "maxTvlReached",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "random_Token_Address",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "send_to_wallet",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "number_of_tokens",
				"type": "uint256"
			}
		],
		"name": "remove_Random_Tokens",
		"outputs": [
			{
				"internalType": "bool",
				"name": "_sent",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "adr",
				"type": "address"
			}
		],
		"name": "secondsSinceLastEat",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newCeoAddress",
				"type": "address"
			}
		],
		"name": "setCeoAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newDevAddress",
				"type": "address"
			}
		],
		"name": "setDevAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newGiveawayAddress",
				"type": "address"
			}
		],
		"name": "setGiveawayAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newMarketingAddress",
				"type": "address"
			}
		],
		"name": "setMarketingAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalBakers",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "adr",
				"type": "address"
			}
		],
		"name": "user",
		"outputs": [
			{
				"components": [
					{
						"internalType": "address",
						"name": "adr",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "beans",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "bakedAt",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ateAt",
						"type": "uint256"
					},
					{
						"internalType": "address",
						"name": "upline",
						"type": "address"
					},
					{
						"internalType": "bool",
						"name": "hasReferred",
						"type": "bool"
					},
					{
						"internalType": "address[]",
						"name": "referrals",
						"type": "address[]"
					},
					{
						"internalType": "address[]",
						"name": "bonusEligibleReferrals",
						"type": "address[]"
					},
					{
						"internalType": "uint256",
						"name": "firstDeposit",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "totalDeposit",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "totalPayout",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "blacklisted",
						"type": "bool"
					}
				],
				"internalType": "struct EPICBEANS.Baker",
				"name": "",
				"type": "tuple"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "adr",
				"type": "address"
			}
		],
		"name": "userBonusEligibleReferrals",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "adr",
				"type": "address"
			}
		],
		"name": "userReferrals",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	}
];

  