export default function Error404() {
    return (
        <div 
            style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                padding: '2rem', 
                maxWidth: '600px', 
                marginTop: '25%' 
            }}
        >
            <h6 style={{ fontSize: '30px', margin: 0 }}>We're working on it</h6>
        </div>
    )
}
