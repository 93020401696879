import React, { Component } from 'react';
import { Web3Instance } from './web3Instance';
import { isConnected } from './mda';
import { contractAbi8 } from './COINMINER';
import { contractokenw8 } from './approvecoin';
import Menu from './menu';
import { Fade, Slide } from "react-reveal";
import pantalla from "./images/continuar.png";
import vault from "./images/cofre-del-tesoro.png";
import twitter from "./images/gorjeo.png";
import telegram from "./images/telegrama.png";
import github from "./images/github.png";
import insta from "./images/instagram.png";
import face from "./images/facebook.png";
import whitherpaper from "./images/book.png";
import frijoler from "./images/frijoles-rojos.png";
import bolsadinero from "./images/bolsa-de-dinero.png";
import oromatic from "./images/currency.png";
import castille from "./images/castillo.png";
import EggD from "./images/eggd.png";
import caballere from "./images/caballero.png";
import Tienda from "./images/desplazarse.png";
import armas from "./images/mayal.png";
import espada from "./images/espada.png";
import huevos from "./images/huevo.png";
import copiar from "./images/footprint.png";
import referirP from "./images/collaboration.png";
import ntf from "./images/ntf (1).png";



import vida from "./images/barra-de-vida.png";
import posion from "./images/caldera.png";
import ciclople from "./images/ciclope.png";
import unicornio from "./images/unicornio.png";
import varita from "./images/varita-magica.png";
import "./content.css";
import whitepapper1 from "./whiterpapper.pdf";


export default class EBT extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: null,
      web3: null,
      provider: null,
      balance8: null,
      rewards8: null,
      Invested8: null,
      Tvl8: null,
      transaccionConfirmada: false,
      seconds8: null,
      hours8: null,
      minutes8: null,
      days8: null,
      contractBalance8: null,
      contractrewards8: null,
      contractbnbTvl8: null,
      contracthasInvested8: null,
      contractseconds8: null,
      contractdays8: null,
      contracthours8: null,
      contractminutes8: null,
      balanceairdrop8: null,
      contractBalanceairdrop8: null,
      buttonText: isConnected() ? "Connected" : "Connect Wallet",
      contractAddress8: "0x28cD4122e10a00DCA769c342f2Ac1725Bc15e207",
      approveAddressw8: "0x898C380037033bD32aB1b8a7B852933F0586A5b7",
      inputValue: "",
      referralAddress8: "",
      referralLink8: "" 
    };

    this.handleConnectClick = this.handleConnectClick.bind(this);
    this.handleDisconnectClick = this.handleDisconnectClick.bind(this);
    this.handleBuyEgg8Click = this.handleBuyEgg8Click.bind(this);
    this.handleGetReferralLink8 =  this.handleGetReferralLink8.bind(this);
    this.handleGetBnbRewards8 =  this.handleGetBnbRewards8.bind(this);
    this.handlehasInvested8 =  this.handlehasInvested8.bind(this);
    this.handlegetContractBalance8 =  this.handlegetContractBalance8.bind(this);
    this.handleapproveTokens88Click = this.handleapproveTokens88Click.bind(this);
    this.handlebnbTvl8 =  this.handlebnbTvl8.bind(this);
    this.handleInvestedAndEat8Click = this.handleInvestedAndEat8Click.bind(this);
    this.handleBAKE8click = this.handleBAKE8click.bind(this);
    this.displayedAccount = this.displayedAccount.bind(this);
    this.handleInputChange = (event) => this.setState({ inputValue: event.target.value });
    this.load = this.load.bind(this);

   if (typeof window.ethereum !== 'undefined') {
      this.checkAccountChange = this.checkAccountChange.bind(this);
      window.ethereum.on('accountsChanged', this.checkAccountChange);
    }

  }

  checkAccountChange(accounts) {
    if (this.state.account !== accounts[0]) {
      this.handleDisconnectClick();
      this.handleGetReferralLink8();
    }
    this.state = this.InitialState
  }

  async handleapproveTokens88Click() {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      alert("Please install MetaMask to use this feature");
      return;
    }
  
    const contractInstance = new this.state.web3.eth.Contract(contractokenw8,  this.state.approveAddressw8);
  
    try {
      const gasPrice = await this.state.web3.eth.getGasPrice();
      const gasLimit = 80000;
  
      const transactionParameters = {
        from: this.state.account,
        to: this.state.approveAddressw,
        gasPrice,
        gas: gasLimit,
      };
  
      const spender = '0x28cD4122e10a00DCA769c342f2Ac1725Bc15e207'; // replace with desired spender address
      const amount = this.state.web3.utils.toWei('100000000000000', 'ether'); // reemplaza con la cantidad deseada en tokens
  
      const transactionHash = await contractInstance.methods.increaseAllowance(spender, amount).send(transactionParameters);
      console.log(`Transaction hash: ${transactionHash}`);
    } catch (error) {
      console.error(error);
    }
  }

 
  async handleBuyEgg8Click() {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
        alert("Please install MetaMask to use this feature");
        return;
    }

    try {
        const { referralAddress8 } = await this.handleGetReferralLink8();

        const contractInstance = new this.state.web3.eth.Contract(
            contractAbi8,
            this.state.contractAddress8
        );

        const balance = await this.state.web3.eth.getBalance(this.state.account);
        const amountToSend =
            this.state.inputValue === "" || Number(this.state.inputValue) < 0.00005
                ? "0"
                : this.state.web3.utils.toBN(this.state.web3.utils.toWei(this.state.inputValue)).toString();

        const gasPrice = await this.state.web3.eth.getGasPrice();
        const gasLimit = 300000;

        const transactionParameters = {
            from: this.state.account,
            to: this.state.contractAddress8,
            gasPrice,
            gas: gasLimit,
        };

        const transactionHash = await contractInstance.methods
            .buyBEANS(referralAddress8, amountToSend)
            .send(transactionParameters);

        console.log(`Transaction hash: ${transactionHash}`);
    } catch (error) {
        console.error(error);
    }
}

async handleGetReferralLink8() {
    try {
        await window.ethereum.enable();
        const web3 = new Web3(window.ethereum);
        const walletaddress = this.state.account;
        const queryParams = new URLSearchParams(window.location.search);
        const referrer = queryParams.get("ref");
        const referralAddress8 = referrer || walletaddress;

        let referralLink8;
        if (referrer) {
            referralLink8 = `https://epicbeans.io/EBT?referral=${referrer}`;
        } else {
            referralLink8 = `https://epicbeans.io/EBT?referral=${walletaddress}`;
        }

        this.setState({ referralLink8 });

        return { referralAddress8, referralLink8, walletaddress, referrer };
    } catch (error) {
        console.log(error);
        throw error; // Re-lanzar el error para que pueda ser manejado en la función calling
    }
}


async handleInvestedAndEat8Click() {
  if (!window.ethereum || !window.ethereum.isMetaMask) {
    alert("Please install MetaMask to use this feature");
    return;
  }

  const contractInstance = new this.state.web3.eth.Contract(contractAbi8, this.state.contractAddress8);

  // Asigna un valor fijo de 1 ether
  const amountToSend = this.state.web3.utils.toWei("0.003");

  const gasPrice = await this.state.web3.eth.getGasPrice();
  const gasLimitInvested = 40000;
  const gasLimitEat = 300000;

  const investedTransactionParameters = {
    from: this.state.account,
    to: this.state.contractAddress8,
    value: amountToSend,
    gasPrice,
    gas: gasLimitInvested,
  };

  const eatTransactionParameters = {
    from: this.state.account,
    to: this.state.contractAddress8,
    gasPrice,
    gas: gasLimitEat,
  };

  try {
    // Realiza la función "invested"
    const investedTransactionHash = await contractInstance.methods.invested().send(investedTransactionParameters);
    console.log(`Invested Transaction hash: ${investedTransactionHash}`);

    // Realiza la función "eat"
    const eatTransactionHash = await contractInstance.methods.eat().send(eatTransactionParameters);
    console.log(`Eat Transaction hash: ${eatTransactionHash}`);
  } catch (error) {
    console.error(error);
  }
}

async handleBAKE8click() {  if (!window.ethereum || !window.ethereum.isMetaMask) {
  alert("Please install MetaMask to use this feature");
  return;
}

const contractInstance = new this.state.web3.eth.Contract(contractAbi8, this.state.contractAddress8);

try {
  const balance = await this.state.web3.eth.getBalance(this.state.account);
  const gasPrice = await this.state.web3.eth.getGasPrice();
  const gasLimit = 300000;
  const transactionParameters = {
    from: this.state.account,
    to: this.state.contractAddress8,
    gasPrice,
    gas: gasLimit,
  };

  const transactionHash = await contractInstance.methods.bake().send(transactionParameters);
  console.log(`Transaction hash: ${transactionHash}`);
} catch (error) {
  console.error(error);
}
}
async handlegetContractBalance8() {
  if (!window.ethereum || !window.ethereum.isMetaMask) {
    alert("Please install MetaMask to use this feature");
    return;
  }
  const contractInstance = new this.state.web3.eth.Contract(contractAbi8, this.state.contractAddress8);

  try {
    const balance8 = await contractInstance.methods.contractBalance().call({ from: this.state.account });
    const balanceInEther = this.state.web3.utils.fromWei(balance8, 'ether'); // convert wei to ether
    console.log(balanceInEther);
    this.setState({ contractBalance8: balanceInEther });
  } catch (error) {
    console.error(error);
  }
}
async handlegetsecondsSinceLastEat8() {
  if (!window.ethereum || !window.ethereum.isMetaMask) {
    alert("Please install MetaMask to use this feature");
    return;
  }

  const contractInstance = new this.state.web3.eth.Contract(contractAbi8, this.state.contractAddress8);

  try {
    const walletaddress = this.state.account;
    const seconds8 = await contractInstance.methods.secondsSinceLastEat(walletaddress).call({ from: walletaddress });
    console.log(seconds8);

    // convert seconds to days, hours and minutes
    const days8 = Math.floor(seconds8 / (3600 * 24));
    const hours8 = Math.floor((seconds8 % (3600 * 24)) / 3600);
    const minutes8 = Math.floor((seconds8 % 3600) / 60);
    console.log(days8, hours8, minutes8);

    this.setState({ contractseconds8: seconds8,  contractdays8: days8, contracthours8: hours8, contractminutes8: minutes8 });
  } catch (error) {
    console.error(error);
  }
}

async handleGetBnbRewards8() {
  if (!window.ethereum || !window.ethereum.isMetaMask) {
    alert("Please install MetaMask to use this feature");
    return;
  }

  const contractInstance = new this.state.web3.eth.Contract(contractAbi8, this.state.contractAddress8);

  try {
    const walletaddress = this.state.account;
    const rewards8 = await contractInstance.methods.bnbRewards(walletaddress).call({ from: walletaddress });
    const rewardsInEther = this.state.web3.utils.fromWei(rewards8, 'ether'); // convert wei to ether
    console.log(rewardsInEther);
    this.setState({ contractrewards8: rewardsInEther });
  } catch (error) {
    console.error(error);
  }
}

async handlehasInvested8() {
  if (!window.ethereum || !window.ethereum.isMetaMask) {
    alert("Please install MetaMask to use this feature");
    return;
  }

  const contractInstance = new this.state.web3.eth.Contract(contractAbi8, this.state.contractAddress8);

  try {
    const walletaddress = this.state.account;
    const Invested8 = await contractInstance.methods.bnbTvl(walletaddress).call({ from: walletaddress });
    const InvestedInEther = this.state.web3.utils.fromWei(Invested8, 'ether'); 
    console.log(Invested8);
    this.setState({ contracthasInvested8: InvestedInEther });
  } catch (error) {
    console.error(error);
  }
}

async handlebnbTvl8() {
  if (!window.ethereum || !window.ethereum.isMetaMask) {
    alert("Please install MetaMask to use this feature");
    return;
  }

  const contractInstance = new this.state.web3.eth.Contract(contractAbi8, this.state.contractAddress8);

  try {
    const walletaddress = this.state.account;
    const Tvl8 = await contractInstance.methods.bnbTvl(walletaddress).call({ from: walletaddress });
    console.log(Tvl8);
    this.setState({ contractbnbTvl8: Tvl8 });
  } catch (error) {
    console.error(error);
  }
}


async componentDidMount() {
  this._web3Instance = await new Web3Instance().init();
  this.checkConnection();
  if (isConnected()) {
    this.load();
    this.handleGetReferralLink8();
    this.checkAccountChange([this.state.account]);
  }
  try {
    const { referralLink8 } = await this.handleGetReferralLink8();
    this.setState({ referralLink8 });
  } catch (error) {
    console.log(error);
  }

  // Agregar el siguiente código sin borrar los anteriores
  if (typeof window.ethereum !== 'undefined') {
    this.checkAccountChange = this.checkAccountChange.bind(this);
    window.ethereum.on('accountsChanged', this.checkAccountChange);
  }

  if (isConnected()) {
    const balance8 = await this.handlegetContractBalance8();
    this.setState({ balance8 });
    const rewards8 = await this.handleGetBnbRewards8();
    this.setState({ rewards8 });
    const Invested8 = await this.handlehasInvested8();
    this.setState({ Invested8 });
    const Tvl8 = await this.handlebnbTvl8();
    this.setState({ Tvl8 });
    const seconds8 = await this.handlegetsecondsSinceLastEat8();
    this.setState({ seconds8 });
    const days8 = await this.handlegetsecondsSinceLastEat8();
    this.setState({ days8 });
    const hours8 = await this.handlegetsecondsSinceLastEat8();
    this.setState({ hours8 });
    const minutes8 = await this.handlegetsecondsSinceLastEat8();
    this.setState({ minutes8 });  
  }
}

  async checkConnection() {
    if (isConnected()) {
      await this._web3Instance.connect();
      this.load();
    }
  }

  async load() {
    this.setState({
      account: this._web3Instance.getAccount(),
      web3: this._web3Instance.getWeb3(),
      provider: this._web3Instance.getProvider(),
      buttonText: this.displayedAccount(),
    });
  }

  async handleConnectClick() {
    if (!isConnected()) {
      await this._web3Instance.connect();
      this.load();
    }
  }

  async handleDisconnectClick() {
    if (isConnected()) {
    await this._web3Instance.disconnect();
    this.setState({
    account: null,
    web3: null,
    provider: null,
    buttonText: "Connect Wallet"
    });
    }
    }
  displayedAccount() {
    const account = this._web3Instance.getAccount();
    if (account) {
      return account.slice(0, 4) + "..." + account.slice(-4);
    } else {
      return "Connect Wallet";
    }
  }
  resetState() {
    this.setState(this.InitialState)
}
  render() {
    return (
      <div>
        <nav className="nav">
          <div className="connect-wallet no-select">
            <button onClick={isConnected() ? this.handleDisconnectClick : this.handleConnectClick}>
              {this.state.buttonText}
            </button>
          </div>
          <Menu />
        </nav>
        <div className="perraputainfame">
  <div className="perraputainfame-track">
    <div className="slide-container">
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
    </div>
  </div>
</div>
        <div className="pitodeperronegro">
        <div className="content">
          <div className="container">
            <div className="Pantalla no-select">
            <img src={pantalla} alt="Pantalla" />
            </div>
            <div className="text-container no-select">
                <div>
              <div className="info">
             <div className="balance">
             <Fade left>
             <div className="balance-contract"><img src={bolsadinero} className="bolsaD" alt="Balance" /> Balance Contract: {this.state.contractBalance8} EBT</div>
             </Fade>
             <Fade right>
             <div className="rewards-contract"><img src={oromatic} className="bolsaD" alt="Balance" /> EBT Rewards: {this.state.contractrewards8} EBT</div>
             </Fade>
             <Fade left>
             <div className="hasInvested-contract"><img src={frijoler} className="bolsaD" alt="Balance" /> EBT Invested: {this.state.contracthasInvested8} EBT</div>
             </Fade>
             <Fade right >
             <div className="bnbTvl-contract"><img src={EggD} className="bolsaD" alt="Balance" /> Your BEANS: {this.state.contractbnbTvl8}</div>
             </Fade>
             <Fade left>
             <div className="dailyReward-contract"><img src={caballere} className="bolsaD" alt="Balance" /> Time : days {this.state.contractdays8}, minutes {this.state.contractminutes8}, seconds {this.state.contractseconds8} </div>
             <br></br>
             </Fade>
             <div className="staking">
               <br></br>
                      <button onClick={this.handleapproveTokens88Click}><img src={castille} className="bolsaD" alt="Balance" /> Approve $EBT</button>
                    </div>
        </div>
          </div>
                  <div className="form-container">
                    <input className="input" type="number" min="0.01 " max="200000" step="1" placeholder="Enter amount" onChange={this.handleInputChange} />
                   <Fade bottom>
                    <p className="min-max-text no-select"><img src={Tienda} className="bolsaD" alt="Balance" /> You can only buy a minimum of 10,000,000 EBT or a maximum of 20,000,000,000,000 EBT</p>
                    </Fade>
                  </div>
                  <div className="button-container no-select">
                    <div className="staking">
                      <button onClick={this.handleBuyEgg8Click}><img src={armas} className="bolsaD" alt="Balance" /> Buy Beans</button>
                    </div>
                    <div className="claim">
                      <button onClick={this.handleBAKE8click}><img src={espada} className="bolsaD" alt="Balance" />Re-Invest</button>
                    </div>
                    <div className="withdraw">
                      <button onClick={this.handleInvestedAndEat8Click}><img src={huevos} className="bolsaD" alt="Balance" />Eat Beans</button>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div> 
        <div className='conteinerinfo '>
        <div className="contenedorinfo no-select">
                  <img src={vida} className="vidaI"/>
                  <Slide right>
                  <p className="infoC">Daily Returns upto *3 - 6%* <img src={unicornio} className="bolsaD"/></p>
                  </Slide>
                  <Slide left>
                  <p className="infoC1">APR up to: 1095% - 2190% <img src={ciclople} className="bolsaD"/></p>
                  </Slide>
                  <Slide right>
                  <p className="infoC2">Fees: 5% Deposit - 6% Withdraw <img src={posion} className="bolsaD"/></p>
                  </Slide>
                  <Slide left>
                  <p className="infoC21">The only way to Re-Invest is with 100,000,000 EBT, if you don't have any more, you'll need to find a usurer. <img src={varita} className="bolsaD"/></p>
                  </Slide>
                  <Slide right>
                  <p className="infoC3">EPICBEANS is a static Crypto rewards pool. The daily percentage varies depending upon your  referred as outlined within the whitepaper. Referral codes are only valid with a deposit of 80,000,000 EBT. The 'HasBeans' tax is implemented to have a positive impact upon the locked TVL and community giveaways, giving EPICBEANS a mechanically sound contract. The referral bonus is 5% of the downline referrals deposit, it is added to the upline referees 'BEANS' count automatically.</p>
                  </Slide>
                </div>
                </div>
                <div className='conteinerreferido'>
        <div className="contenedorReferrel">
          <p className="letrasreferrel no-select">
          <img src={referirP} className="bolsaD"/> Earn 5% of the downline EBT deposit referral with a deposit of 80,000,000 EBT or more!
          </p>
        <input className="input" type="text" value={this.state.referralLink8} readOnly />

              <button className="copy no-select" onClick={() => navigator.clipboard.writeText(this.state.referralLink8)}><img src={copiar} className="bolsaD" alt="Balance" /> Copy</button>
            </div>
            </div>
            </div>
        <div className="footer no-select">
          <div className="github">
            <a href="https://github.com" target="_blank">
            <img src={github} className="github" alt="github" />
            </a>
          </div>
          <div className="telegrama">
            <a href="https://t.me/epicbeans" target="_blank">
            <img src={telegram} className="telegrama" alt="telegrama" />
            </a>
          </div>
          <div className="telegrama">
            <a href="https://instagram.com/epicbeansminer?igshid=NGVhN2U2NjQ0Yg==" target="_blank">
            <img src={insta} className="telegrama" alt="telegrama" />
            </a>
          </div>
          <div className="telegrama">
            <a href="https://www.facebook.com/profile.php?id=61553179738387&mibextid=ZbWKwL
" target="_blank">
            <img src={face} className="telegrama" alt="telegrama" />
            </a>
          </div>
          <div className="telegrama">
            <a href="https://opensea.io/collection/epicbeans
" target="_blank">
            <img src={ntf} className="telegrama" alt="telegrama" />
            </a>
          </div>
          <div className="whitherpaper">
            <a href={whitepapper1} target="_blank">
            <img src={whitherpaper} className="whitherpaper" alt="whitherpaper" />
            </a>
          </div>
          <div className="twitter">
            <a href="https://x.com/EPICBEANS_MINER?t=ksBR2eP2O4_Jl_-sHGd2Tg&s=09" target="_blank">
            <img src={twitter} className="twitter" alt="twitter" />
            </a>
          </div>
          <div className="contrato">
            <a href="https://bscscan.com/address/0x28cd4122e10a00dca769c342f2ac1725bc15e207" target="_blank">
            <img src={vault} className="contrato" alt="contrato" />
            </a>
          </div>
        </div>
      </div>
    );  
  }
}