import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Content from './components/Content';
import Error404 from './components/Error404';
import ESCROW from './components/ESCROW';
import AIRDROP from './components/AIRDROP';
import EBT from './components/EBT';
import LOTTERY from './components/LOTTERY';

function App() {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/" component={Content} />
          <Route exact path="/ESCROW" component={ESCROW} />
          <Route exact path="/AIRDROP" component={AIRDROP} />
          <Route exact path="/EBT" component={EBT} />
          <Route exact path="/LOTTERY" component={LOTTERY} />
          <Route path="*" component={Error404} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
