// Menu.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import menu from "./images/menu.png";
import "./menu.css";

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  }

  return (
    <div className={`menu ${isOpen ? 'open' : ''}`}>
      <div className="menu-icon" onClick={handleMenuClick}>
        <img src={menu} className="menus" alt="menu" />
      </div>
      {isOpen && (
        <ul className="menu-items">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/ESCROW">ESCROW</Link></li>
          <li><Link to="/AIRDROP">AIRDROP</Link></li>
          <li><Link to="/EBT">EBT</Link></li>
          <li><Link to="/LOTTERY">LOTTERY</Link></li>
        </ul>
      )}
    </div>
  );
};

export default Menu;
