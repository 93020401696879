import React, { Component } from 'react';
import { Web3Instance } from './web3Instance';
import { isConnected } from './mda';
import { contractAirdrop } from './claim';
import { Slide, Fade } from "react-reveal";

import Menu from './menu';
import pantalla from "./images/continuar1.png";
import ntf from "./images/ntf (1).png";

import cereales from "./images/lata.png";
import vasol from "./images/comicw.png";
import airdrop76 from "./images/airdrop.png";
import vault from "./images/cofre-del-tesoro.png";
import twitter from "./images/gorjeo.png";
import telegram from "./images/telegrama.png";
import github from "./images/github.png";
import insta from "./images/instagram.png";
import face from "./images/facebook.png";
import whitherpaper from "./images/book.png";
import "./content.css";
import whitepapper1 from "./whiterpapper.pdf";

export default class AIRDROP extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        account: null,
        web3: null,
        provider: null,
        percentageRemaining: 99,
        buttonText: isConnected() ? "Connected" : "Connect Wallet",
        airdropAddress: "0x09fB6678129e98636E0f45525bf42097055De911",

        inputValue: "0.001",
        referralAddress: "",
        referralLink: "" 
      };
  
      this.handleConnectClick = this.handleConnectClick.bind(this);
      this.handleDisconnectClick = this.handleDisconnectClick.bind(this);
      this.handleGetReferralLink =  this.handleGetReferralLink.bind(this);
      this.handleclaimairdropClick = this.handleclaimairdropClick.bind(this);
      this.handlegetContractRemanigsairdrop = this.handlegetContractRemanigsairdrop.bind(this);
      this.displayedAccount = this.displayedAccount.bind(this);
      this.handleInputChange = (event) => this.setState({ inputValue: event.target.value });
      this.load = this.load.bind(this);


   if (typeof window.ethereum !== 'undefined') {
      this.checkAccountChange = this.checkAccountChange.bind(this);
      window.ethereum.on('accountsChanged', this.checkAccountChange);
    }

  }
  checkAccountChange(accounts) {
    if (this.state.account !== accounts[0]) {
      this.handleDisconnectClick();
      this.handleGetReferralLink();
    }
    this.state = this.InitialState
  }

async handleGetReferralLink () {
    try {
      await window.ethereum.enable();
      const web3 = new Web3(window.ethereum);
      const walletaddress = this.state.account;
      const queryParams = new URLSearchParams(window.location.search);
      const referrer = queryParams.get('ref');
      const referralAddress = referrer || walletaddress; // <-- Update referralAddress to be referrer or walletaddress
      const referralLink = `https://epicbeans.io/?referral=${walletaddress}`;
      this.setState({ referralLink }); // <-- Add this line to update referralLink state
      return { referralAddress, referralLink, walletaddress };
    } catch (error) {
      console.log(error);
    }
}

async handleclaimairdropClick() {
  if (!window.ethereum || !window.ethereum.isMetaMask) {
    alert("Please install MetaMask to use this feature");
    return;
  }

  const contractInstance = new this.state.web3.eth.Contract(contractAirdrop, this.state.airdropAddress);

  const balance = await this.state.web3.eth.getBalance(this.state.account);
  const amountToSend = this.state.inputValue3 === "" || Number(this.state.inputValue) < 0.001
    ? "0"
    : this.state.web3.utils.toBN(this.state.web3.utils.toWei(this.state.inputValue)).toString();

  const gasPrice = await this.state.web3.eth.getGasPrice();
  const gasLimit = 100000;

  const transactionParameters = {
    from: this.state.account,
    to: this.state.airdropAddress,
    value: amountToSend,
    gasPrice,
    gas: gasLimit,
  };

  try {
    const transactionHash = await contractInstance.methods.claimTokens().send(transactionParameters);
    console.log(`Transaction hash: ${transactionHash}`);
  } catch (error) {
    console.error(error);
  }
}

  async handlegetContractRemanigsairdrop() {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      alert("Please install MetaMask to use this feature");
      return;
    }
    const contractInstance = new this.state.web3.eth.Contract(contractAirdrop, this.state.airdropAddress);
  
    try {
      const totalTokens = await contractInstance.methods.totalTokens().call({ from: this.state.account });
      const tokensPerClaim = await contractInstance.methods.tokensPerClaim().call({ from: this.state.account });
      const claimsRemaining = await contractInstance.methods.claimsRemaining().call({ from: this.state.account });
  
      const percentageRemaining = Math.max(0, (claimsRemaining / (totalTokens / tokensPerClaim)) * 99);
  
      console.log(`Claims Remaining: ${claimsRemaining}`);
      console.log(`GETTING TO ZERO GETS LISTED ON AN EXCHANGE: ${percentageRemaining}%`);
      this.setState({ percentageRemaining });
  
      // Actualiza la barra de progreso
      const progressBar = document.getElementById('progressBar');
      if (progressBar) {
        progressBar.style.width = `${percentageRemaining}%`;
      }
  
      // Actualiza el porcentaje en algún elemento HTML
      document.getElementById('percentageDisplay').innerText = `GETTING TO ZERO GETS LISTED ON AN EXCHANGE: ${percentageRemaining}%`;
  
      // También puedes utilizar este valor para realizar otras actualizaciones necesarias.
      // updateOtherComponents(percentageRemaining);
  
    } catch (error) {
      console.error(error);
    }
  }

async componentDidMount() {
  this._web3Instance = await new Web3Instance().init();
  this.checkConnection();
  if (isConnected()) {
    this.load();
    this.handleGetReferralLink();
    this.checkAccountChange([this.state.account]);
  }
  try {
    const { referralLink } = await this.handleGetReferralLink();
    this.setState({ referralLink });
  } catch (error) {
    console.log(error);
  }

  // Agregar el siguiente código sin borrar los anteriores
  if (typeof window.ethereum !== 'undefined') {
    this.checkAccountChange = this.checkAccountChange.bind(this);
    window.ethereum.on('accountsChanged', this.checkAccountChange);
  }

  if (isConnected()) {
    await this.handlegetContractRemanigsairdrop();
  }
}

  async checkConnection() {
    if (isConnected()) {
      await this._web3Instance.connect();
      this.load();
    }
  }

  async load() {
    this.setState({
      account: this._web3Instance.getAccount(),
      web3: this._web3Instance.getWeb3(),
      provider: this._web3Instance.getProvider(),
      buttonText: this.displayedAccount(),
    });
  }

  async handleConnectClick() {
    if (!isConnected()) {
      await this._web3Instance.connect();
      this.load();
    }
  }

  async handleDisconnectClick() {
    if (isConnected()) {
    await this._web3Instance.disconnect();
    this.setState({
    account: null,
    web3: null,
    provider: null,
    buttonText: "Connect Wallet"
    });
    }
    }
  displayedAccount() {
    const account = this._web3Instance.getAccount();
    if (account) {
      return account.slice(0, 4) + "..." + account.slice(-4);
    } else {
      return "Connect Wallet";
    }
  }
  resetState() {
    this.setState(this.InitialState)
}
render() {
    return (
      <div>
        <nav className="nav">
          <div className="connect-wallet">
            <button onClick={isConnected() ? this.handleDisconnectClick : this.handleConnectClick}>
              {this.state.buttonText}
            </button>
          </div>
          <Menu />
        </nav>
        <div className="perraputainfame">
  <div className="perraputainfame-track">
    <div className="slide-container">
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
    </div>
  </div>
</div>
        <div className="pitodeperronegro">
        <div className="content">
          <div className="container">
          <Slide right>
            <div className="Pantalla">
            <img src={pantalla} alt="Pantalla" />
            </div>
            </Slide>
            <div className="text-container">
              <div>
            <div className="info5">
           <div className="balance16">
           <div>
            <div id="progressBarContainer">
                <div id="progressBar" style={{ width: `${this.state.percentageRemaining}%` }}></div>
            </div><br></br>
            <Fade bottom>
            <div id="percentageDisplay">GETTING TO ZERO GETS LISTED ON AN EXCHANGE: {this.state.percentageRemaining}%</div>
            </Fade>
        </div>
        <Fade top>
           <div className="dailyReward-contract198"><img src={cereales} className="bolsaD" alt="Balance" /> Fees: 0.001 BNB</div>
           </Fade>
      </div>      
        </div>
                <Slide left>
                <div className="form-container">
                  <p className="min-max-text"><img src={vasol} className="bolsaD" alt="Balance" /> You will claim 10,000,000 $EBT tokens.</p>
                </div>
                </Slide>
                <div className="button-container">
                  <div className="staking">
                    <button onClick={this.handleclaimairdropClick}><img src={airdrop76} className="bolsaD" alt="Balance" /> Claim</button>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      </div> 
      <div className="footer no-select">
          <div className="github">
            <a href="https://github.com" target="_blank">
            <img src={github} className="github" alt="github" />
            </a>
          </div>
          <div className="telegrama">
            <a href="https://t.me/epicbeans" target="_blank">
            <img src={telegram} className="telegrama" alt="telegrama" />
            </a>
          </div>
          <div className="telegrama">
            <a href="https://instagram.com/epicbeansminer?igshid=NGVhN2U2NjQ0Yg==" target="_blank">
            <img src={insta} className="telegrama" alt="telegrama" />
            </a>
          </div>
          <div className="telegrama">
            <a href="https://www.facebook.com/profile.php?id=61553179738387&mibextid=ZbWKwL
" target="_blank">
            <img src={face} className="telegrama" alt="telegrama" />
            </a>
          </div>
          <div className="telegrama">
            <a href="https://opensea.io/collection/epicbeans
" target="_blank">
            <img src={ntf} className="telegrama" alt="telegrama" />
            </a>
          </div>
          <div className="whitherpaper">
            <a href={whitepapper1} target="_blank">
            <img src={whitherpaper} className="whitherpaper" alt="whitherpaper" />
            </a>
          </div>
          <div className="twitter">
            <a href="https://x.com/EPICBEANS_MINER?t=ksBR2eP2O4_Jl_-sHGd2Tg&s=09" target="_blank">
            <img src={twitter} className="twitter" alt="twitter" />
            </a>
          </div>
          <div className="contrato">
            <a href="https://bscscan.com/address/0x09fB6678129e98636E0f45525bf42097055De911" target="_blank">
            <img src={vault} className="contrato" alt="contrato" />
            </a>
          </div>
        </div>
      </div>
    );  
  }
}