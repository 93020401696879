import React, { Component } from 'react';
import { Web3Instance } from './web3Instance';
import { isConnected } from './mda';
import { Fade, Zoom, LightSpeed, Slide } from "react-reveal";
import Menu from './menu';
import vault from "./images/cofre-del-tesoro.png";
import twitter from "./images/gorjeo.png";
import telegram from "./images/telegrama.png";
import github from "./images/github.png";
import insta from "./images/instagram.png";
import face from "./images/facebook.png";
import gif1 from "./images/i-game-3.gif";
import gif2 from "./images/i-game-1.gif";
import gif3 from "./images/i-game-4.gif";
import gif4 from "./images/i-game-5.gif";
import whitherpaper from "./images/book.png";
import ntf from "./images/ntf (1).png";
import giferto from "./images/EBT.gif";
import Polygator from "./images/5cc1d00679bc362c2aca3ec64ccb6370.png";
import Maticminer from "./images/e881e884485dccf4ec69f3962799c5ae.png";
import BearsMiners from "./images/e029525dae8c17a4394e93ee43f41878.png";
import bnbroi from "./images/afcbba70808073d49b30eae612928c26.png";
import flashloanbnb from "./images/unnamed.png";
import coinminerpro from "./images/58d23d5197f716116f9e6121e4daa725.png";
import tresdimage from "./images/Sin título-1.png";
import Roadmap from './Roadmap';  // Importa el componente de roadmap
import "./content.css";
import whitepapper1 from "./whiterpapper.pdf";

export default class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: null,
      web3: null,
      provider: null,
      buttonText: isConnected() ? "Connected" : "Connect Wallet",
      referralAddress8: "",
      referralLink8: "",
      contractaddress:"0x898C380037033bD32aB1b8a7B852933F0586A5b7"
    };

    this.handleConnectClick = this.handleConnectClick.bind(this);
    this.handleDisconnectClick = this.handleDisconnectClick.bind(this);
    this.handleGetReferralLink8 = this.handleGetReferralLink8.bind(this);
    this.load = this.load.bind(this);

    if (typeof window.ethereum !== 'undefined') {
       this.checkAccountChange = this.checkAccountChange.bind(this);
       window.ethereum.on('accountsChanged', this.checkAccountChange);
     }
 
   }
 
   checkAccountChange(accounts) {
     if (this.state.account !== accounts[0]) {
       this.handleDisconnectClick();
       this.handleGetReferralLink8();
     }

     this.state = this.InitialState
  }
  
  async handleGetReferralLink8() {
    try {
      await window.ethereum.enable();
      const web3 = new Web3(window.ethereum);
      const walletaddress = this.state.account;
      const queryParams = new URLSearchParams(window.location.search);
      const referrer = queryParams.get("ref");
      const referralAddress8 = referrer || walletaddress;

      let referralLink8;
      if (referrer) {
        referralLink8 = `https://epicbeans.io/?referral=${referrer}`;
      } else {
        referralLink8 = `https://epicbeans.io/?referral=${walletaddress}`;
      }

      this.setState({ referralLink8 });

      return { referralAddress8, referralLink8, walletaddress, referrer };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  async componentDidMount() {
    this._web3Instance = await new Web3Instance().init();
    this.checkConnection();
    if (isConnected()) {
      this.load();
      this.handleGetReferralLink8();
      this.checkAccountChange([this.state.account]);
    }
    try {
      const { referralLink8 } = await this.handleGetReferralLink8();
      this.setState({ referralLink8 });
    } catch (error) {
      console.log(error);
    }
  
    // Agregar el siguiente código sin borrar los anteriores
    if (typeof window.ethereum !== 'undefined') {
      this.checkAccountChange = this.checkAccountChange.bind(this);
      window.ethereum.on('accountsChanged', this.checkAccountChange);
    }
  
    if (isConnected()) {
      // Resto del código...
    }
  
    // Agrega el código del evento animationiteration aquí
    var element = document.querySelector(".spanish");
  
    element.addEventListener("animationiteration", function () {
      // Desactiva la animación y espera 100 milisegundos antes de volver a activarla
      element.style.animation = "none";
      setTimeout(function () {
        void element.offsetWidth; // Trigger reflow
        element.style.animation = "typing 6s steps(15), blink 2s infinite step-end alternate";
      }, 500);
    });

  }
  
  async checkConnection() {
    if (isConnected()) {
      await this._web3Instance.connect();
      this.load();
    }
  }

  async load() {
    this.setState({
      account: this._web3Instance.getAccount(),
      web3: this._web3Instance.getWeb3(),
      provider: this._web3Instance.getProvider(),
      buttonText: this.displayedAccount(),
    });
  }

  async handleConnectClick() {
    if (!isConnected()) {
      await this._web3Instance.connect();
      this.load();
    }
  }

  async handleDisconnectClick() {
    if (isConnected()) {
      await this._web3Instance.disconnect();
      this.setState({
        account: null,
        web3: null,
        provider: null,
        buttonText: "Connect Wallet"
      });
    }
  }

  displayedAccount() {
    const account = this._web3Instance.getAccount();
    if (account) {
      return account.slice(0, 4) + "..." + account.slice(-4);
    } else {
      return "Connect Wallet";
    }
  }

  resetState() {
    this.setState(this.InitialState);
  }
  render() {
    return (
      <div>
        <nav className="nav">
          <div className="connect-wallet no-select">
            <button onClick={isConnected() ? this.handleDisconnectClick : this.handleConnectClick}>
              {this.state.buttonText}
            </button>
          </div>
          <Menu />
        </nav>
        <div className="perraputainfame">
  <div className="perraputainfame-track">
    <div className="slide-container">
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
    </div>
  </div>
</div>
<div className="pitodeperronegro">
        <div id="Home"></div>
        <div className="conteinerinicio">
          <div className="fondito">
  <div className="letrasconteiner34">
  <div className="slide-textp">
  <h2 className="perravergaw">EPICBEANS:</h2>
  <div className="contryvrg">
    <ul className="slide-list">
      <li >MEMECOIN</li>
    </ul>
  </div>
</div>        
  <div className="segundoconteiner">
    <Fade top>
    <div className="imgeneitor">
      <img src={giferto} className="bolsaqr no-select" alt="Balance" />
    </div>
    </Fade>
    <div className="conteinercontrato">
      <div className="classparaspan">
        <span className="spanish">
          0x898...6A5b7
        </span>
      </div>
      <button
  className="copycontract no-select"
  onClick={(e) => {
    e.preventDefault();
    try {
      navigator.clipboard.writeText(this.state.contractaddress);
      console.log("¡Dirección del contrato copiada!");
    } catch (error) {
      console.error("Error al copiar la dirección del contrato:", error);
    }
  }}
>
<img src={gif2} className="bolsaD no-select" alt="Balance" /> Copy Contract
</button>
    </div>
  </div>
</div>
</div>
</div>  
<div className="conteinerinfotoken1">
<div className="conteinerinfotoken2">
<h1 className="letrastokenff no-select">TOKEN $EBT</h1>  
<Zoom left cascade> 
<h1 className="titulwe4 no-select">Decentralization in the context of the $EBT token backed by a DApp and multiple smart contracts implies the equitable distribution of functions and decisions on the blockchain network. Here are some details about decentralization:</h1>
</Zoom>
  <h1 className="titulwe no-select">Decentralized Smart Contract: </h1>
  <LightSpeed right>
  <p className="letrasinfotoken1 no-select">The smart contract associated with the token operates on the blockchain in a decentralized manner. This means it is not controlled by a single entity and operates automatically according to predefined rules, without the need for a central authority.</p>
  </LightSpeed>
  <h1 className="titulwe no-select">Autonomous Decision-Making:</h1>
  <LightSpeed left>
  <p className="letrasinfotoken1 no-select">The rules of the smart contract governing reward distribution are transparent and cannot be unilaterally altered. Decision-making is embedded in the smart contract code and is based on predefined logic, eliminating the need to trust a central entity to modify them.</p>
  </LightSpeed>
  <h1 className="titulwe no-select">Enhanced Security:</h1>
  <LightSpeed right>
  <p className="letrasinfotoken1 no-select">Decentralization reduces risks associated with a single point of failure. Being distributed across network nodes, the smart contract is more resistant to potential attacks or manipulations.</p>
  </LightSpeed>
  <h1 className="titulwe no-select">Active Community Participation:</h1>
  <LightSpeed left>
  <p className="letrasinfotoken1 no-select">Decentralization encourages active community participation. Users may have a role in decision-making through decentralized proposals or voting, contributing to a more inclusive ecosystem.</p>
  </LightSpeed>
  <h1 className="titulwe no-select">Elimination of Intermediaries:</h1>
  <LightSpeed right>
  <p className="letrasinfotoken1 no-select">By decentralizing, the need for intermediaries or third parties to manage the smart contract is eliminated. This reduces costs and increases system efficiency.</p>
  </LightSpeed>
  <h1 className="titulwe no-select">Transparency:</h1>
  <LightSpeed left>
  <p className="letrasinfotoken1 no-select">All transactions and operations on the blockchain network are transparent and verifiable by any community member. This contributes to trust and the integrity of the system.</p>
  </LightSpeed>
  <Fade bottom cascade> 
  <h1 className="titulwe4 no-select">These decentralization elements help establish a reliable and resilient environment where participants can trust in the autonomy and inherent security of the token and the associated DApp, transforming the meme token into a super project due to its ability to burn tokens with fees from static mining, escrow, lottery, and other applications that will be released in our roadmap.</h1>
  </Fade>
  </div>
  </div>
  <div  className="conteinerinicio">
    <div className="fondito">
  <div  className="conteinerinfotoken243">
    <div className="separadortop">
  <h2 className="letrastokenPROGOLD no-select">LIQUIDITY</h2>
  <h2 className="wavegold no-select">LIQUIDITY</h2>
  </div>
  <Fade top>
  <div className="imgeneLIQUIDEZ">
      <img src={tresdimage} className="bolsaqrjk no-select" alt="Balance" />
    </div>
    </Fade>
    <div className="botonliquidez">
  <a className="sasrt no-select"  href="https://dx.app/dxlockview?id=0&add=0xD31c3C7fB4F9DEFB9cf2959CF251e8F17A34ba80&type=lplock&chain=BNB
" target="_blank" rel="noopener noreferrer"><img src={gif1} className="bolsaD no-select" alt="Balance" /> Check LP locked</a>
  </div>
</div>
  </div>
  </div>
  <div className="conteinerinfotoken1">
    <Roadmap />
  </div>
  <div  className="conteinerinicio">
    <div className="fondito">
  <div  className="conteinerinfotoken243">
  <h1 className="letrastokenff999 no-select">FEES $EBT</h1> 
    <div className="feesporque"> 
    <Slide left>
      <h3 className="slaves no-select">
      Everyone likes it when a cryptocurrency goes up and doesn't understand that for this to happen, a community must be built. However, we have to create a community that works for the project. They are not slaves; they will work for themselves and pay themselves. Although that may sound like slavery, with extra steps, these are necessary elements used to list the project on exchanges, get listings, and advertise, as well as to burn tokens and reduce the supply
      </h3>
      </Slide>
    </div>
  <div className="botonliquidez">
  <a className="sasrt"  href="https://bscscan.com/address/0x28cd4122e10a00dca769c342f2ac1725bc15e207" target="_blank" rel="noopener noreferrer"><img src={gif4} className="bolsaD no-select" alt="Balance" /> Check Contract</a>
  </div>
    </div>
    </div>
    </div>
    <div  className="conteinerinicio1z no-select">
               <div className="contenedorparner no-select">
               <h1 className="letrastokenffzzsd no-select">PARNERS</h1> 
               <div className="socios-row">
                 <Slide left>
               <div className="Polygator no-select">
             <a href="https://coinmarketcap.com/dexscan/bsc/0xd31c3c7fb4f9defb9cf2959cf251e8f17a34ba80/" target="_blank">
             <img src={Polygator} />
             </a>
           </div>
           </Slide>
           <Slide right>
           <div className="Maticminer no-select">
             <a href="https://www.geckoterminal.com/bsc/pools/0xd31c3c7fb4f9defb9cf2959cf251e8f17a34ba80?utm_source=coingecko&utm_medium=referral&utm_campaign=searchresults" target="_blank">
             <img src={Maticminer} />
             </a>
           </div>
           </Slide>
           <Slide left>
           <div className="BearsMiners no-select">
             <a href="https://pancakeswap.finance/swap?outputCurrency=0x898C380037033bD32aB1b8a7B852933F0586A5b7" target="_blank">
             <img src={BearsMiners} />
             </a>
           </div>
           </Slide>
           <Slide right>
           <div className="coinminerpro no-select">
             <a href="https://www.dexview.com/bsc/0x898C380037033bD32aB1b8a7B852933F0586A5b7" target="_blank">
             <img src={coinminerpro} />
             </a>
           </div>
           </Slide>
           <Slide left>
           <div className="bnbroi no-select">
             <a href="https://www.dextools.io/app/en/bnb/pair-explorer/0xd31c3c7fb4f9defb9cf2959cf251e8f17a34ba80" target="_blank">
             <img src={bnbroi}/>
             </a>
           </div>
           </Slide>
           <Slide right>
           <div className="flashloanbnb no-select">
             <a href="https://dexscreener.com/bsc/0xd31c3c7fb4f9defb9cf2959cf251e8f17a34ba80" target="_blank">
             <img src={flashloanbnb}  />
             </a>
           </div>
           </Slide>
             </div>
             </div>
             </div>
    <a href="#Home" className="scroll-down scroll-down-link no-select">
          <svg xmlns="http://www.w3.org/2000/svg" width="1.7em" height="1.7em" fill="currentColor" className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"/>
</svg>
</a>
</div>
        <div className="footer">
          <div className="github">
            <a href="https://github.com" target="_blank">
            <img src={github} className="github no-select" alt="github" />
            </a>
          </div>
          <div className="telegrama">
            <a href="https://t.me/epicbeans" target="_blank">
            <img src={telegram} className="telegrama no-select" alt="telegrama" />
            </a>
          </div>
          <div className="telegrama">
            <a href="https://instagram.com/epicbeansminer?igshid=NGVhN2U2NjQ0Yg==" target="_blank">
            <img src={insta} className="telegrama no-select" alt="telegrama" />
            </a>
          </div>
          <div className="telegrama">
            <a href="https://www.facebook.com/profile.php?id=61553179738387&mibextid=ZbWKwL
" target="_blank">
            <img src={face} className="telegrama no-select" alt="telegrama" />
            </a>
          </div>
          <div className="telegrama">
            <a href="https://opensea.io/collection/epicbeans
" target="_blank">
            <img src={ntf} className="telegrama no-select" alt="telegrama" />
            </a>
          </div>
          <div className="whitherpaper">
            <a href={whitepapper1} target="_blank">
            <img src={whitherpaper} className="whitherpaper no-select" alt="whitherpaper" />
            </a>
          </div>
          <div className="twitter">
            <a href="https://x.com/EPICBEANS_MINER?t=ksBR2eP2O4_Jl_-sHGd2Tg&s=09" target="_blank">
            <img src={twitter} className="twitter no-select" alt="twitter" />
            </a>
          </div>
          <div className="contrato">
            <a href="https://bscscan.com/address/0x898c380037033bd32ab1b8a7b852933f0586a5b7" target="_blank">
            <img src={vault} className="contrato no-select" alt="contrato" />
            </a>
          </div>
        </div>
      </div>
    );  
  }
}