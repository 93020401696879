import React, { Component } from 'react';
import { Web3Instance } from './web3Instance';
import { isConnected } from './mda';
import { contractEscrow } from './escrowabi';
import { contractokenw8 } from './approvecoin';
import { Slide, Fade } from "react-reveal";
import Menu from './menu';
import pantalla from "./images/continuar1.png";
import caballere from "./images/caballero.png";
import ntf from "./images/ntf (1).png";
import approvater from "./images/stamp.png";
import castille from "./images/castillo.png";
import idimg from "./images/informacion-personal.png";
import information2 from "./images/acerca-de.png";
import idimgregistro from "./images/reconocimiento-facial.png";
import comprador from "./images/buyer.png";
import vendedor from "./images/agent.png";
import checker from "./images/insignia.png";
import bolsadinero from "./images/bolsa-de-dinero.png";
import vault from "./images/cofre-del-tesoro.png";
import armas from "./images/mayal.png";
import twitter from "./images/gorjeo.png";
import telegram from "./images/telegrama.png";
import github from "./images/github.png";
import insta from "./images/instagram.png";
import face from "./images/facebook.png";
import whitherpaper from "./images/book.png";
import "./content.css";
import whitepapper1 from "./whiterpapper.pdf";

export default class ESCROW extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        account: null,
        web3: null,
        provider: null,
        contractamountte: null,
        amountte: null,
        contracttransaction1: null,
        firstValue: null,
        infotransaction: null,
        infotransaction2: null,
        infotransaction1: null,
        contractInfo: null,
        transaccionConfirmada: false,
        infotransactionseller: null,
        infotransactionseller1: null,
        infotransactionseller2: null,
        infotransactionseller3: null,
        infotransactionseller4: null,
        contracttransactionSeller: null,
        contracttransactionSeller1: null,
        contracttransactionSeller2: null,
        contracttransactionSeller3: null,
        contracttransactionSeller4: null,
        buttonText: isConnected() ? "Connected" : "Connect Wallet",
        EscrowAddress: "0xb2eeCf3771b6541ea03aD128cCaede0D89801EC0",
        approveAddressw8: "0x898C380037033bD32aB1b8a7B852933F0586A5b7",
        inputValue: "",
        walletAddressinput: "",
        walletAddressinput2: "",
        numberOfAmount: "",
        numberOfID: "",
        referralAddress: "",
        referralLink: "" 
      };
  
      this.handleConnectClick = this.handleConnectClick.bind(this);
      this.handleDisconnectClick = this.handleDisconnectClick.bind(this);
      this.handleGetReferralLink =  this.handleGetReferralLink.bind(this);
      this.displayedAccount = this.displayedAccount.bind(this);
      this.handleGetTransactionAmount = this.handleGetTransactionAmount.bind(this);
      this.handleBuyer = this.handleBuyer.bind(this);
      this.handleSeller = this.handleSeller.bind(this);
      this.handleinfoSellerClick = this.handleinfoSellerClick.bind(this);
      this.handleApproveEscrowClick = this.handleApproveEscrowClick.bind(this);
      this.handleapproveTokens88Click = this.handleapproveTokens88Click.bind(this);
      this.handleCreateEscrow = this.handleCreateEscrow.bind(this);
      this.handleApprovate = this.handleApprovate.bind(this);
      this.handlescrowIdinfo =  this.handlescrowIdinfo.bind(this);
      this.handleInputChange9 = (event) =>  this.setState({ numberOfAmount: event.target.value });
      this.handleInputChange12 = (event) => {
        const input = event.target.value;
        const walletAddressinput = input.replace(/[^0-9a-fA-Fx]/g, '').substring(0, 42); // Elimina todo lo que no sea letra, número o "x"
        this.setState({ walletAddressinput });
      }
      this.handleInputChange24 = (event) => {
        const input = event.target.value;
        const walletAddressinput2 = input.replace(/[^0-9a-fA-Fx]/g, '').substring(0, 42); // Elimina todo lo que no sea letra, número o "x"
        this.setState({ walletAddressinput2 });
      }
      this.handleInputChange100 = (event) =>  this.setState({ numberOfID: event.target.value });
      this.load = this.load.bind(this);


   if (typeof window.ethereum !== 'undefined') {
      this.checkAccountChange = this.checkAccountChange.bind(this);
      window.ethereum.on('accountsChanged', this.checkAccountChange);
    }

  }
  checkAccountChange(accounts) {
    if (this.state.account !== accounts[0]) {
      this.handleDisconnectClick();
      this.handleGetReferralLink();
    }
  }

async handleGetReferralLink () {
    try {
      await window.ethereum.enable();
      const web3 = new Web3(window.ethereum);
      const walletaddress = this.state.account;
      const queryParams = new URLSearchParams(window.location.search);
      const referrer = queryParams.get('ref');
      const referralAddress = referrer || walletaddress; // <-- Update referralAddress to be referrer or walletaddress
      const referralLink = `https://epicbeans.io/?referral=${walletaddress}`;
      this.setState({ referralLink }); // <-- Add this line to update referralLink state
      return { referralAddress, referralLink, walletaddress };
    } catch (error) {
      console.log(error);
    }
}
async handleapproveTokens88Click() {
  if (!window.ethereum || !window.ethereum.isMetaMask) {
    alert("Please install MetaMask to use this feature");
    return;
  }

  const contractInstance = new this.state.web3.eth.Contract(contractokenw8,  this.state.approveAddressw8);

  try {
    const gasPrice = await this.state.web3.eth.getGasPrice();
    const gasLimit = 80000;

    const transactionParameters = {
      from: this.state.account,
      to: this.state.approveAddressw,
      gasPrice,
      gas: gasLimit,
    };

    const spender = '0xb2eeCf3771b6541ea03aD128cCaede0D89801EC0'; // replace with desired spender address
    const amount = this.state.web3.utils.toWei('100000000000000', 'ether'); // reemplaza con la cantidad deseada en tokens

    const transactionHash = await contractInstance.methods.increaseAllowance(spender, amount).send(transactionParameters);
    console.log(`Transaction hash: ${transactionHash}`);
  } catch (error) {
    console.error(error);
  }
}
async handleGetTransactionAmount() {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      alert("Please install MetaMask to use this feature");
      return;
    }
  
    const contractInstance = new this.state.web3.eth.Contract(contractEscrow, this.state.EscrowAddress);
  
    try {
      const walletaddress = this.state.account;
      const Info = await contractInstance.methods.getTransactionInfo(walletaddress).call({ from: walletaddress });
      const firstValue = Info[0]; // Obtén el primer valor uint256 del array "Info"
      console.log(firstValue);
  
      const amountte = await contractInstance.methods.transactionIdToAmount(firstValue).call({ from: walletaddress });
      const amountteInEther = this.state.web3.utils.fromWei(amountte, 'ether'); // convert wei to ether
      console.log(amountteInEther);
  
      this.setState({ contractamountte: amountteInEther });
    } catch (error) {
      console.error(error);
    }
  }
  async handleSeller() {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      alert("Please install MetaMask to use this feature");
      return;
    }
  
    const contractInstance = new this.state.web3.eth.Contract(contractEscrow, this.state.EscrowAddress);
  
    try {
      const walletaddress = this.state.account;
      const Info = await contractInstance.methods.getTransactionInfo(walletaddress).call({ from: walletaddress });
      const firstValue = Info[0]; // Obtén el primer valor uint256 del array "Info"
      console.log(firstValue);
  
      const transaction = await contractInstance.methods.transactions(firstValue).call({ from: walletaddress });
      const infotransaction1 = transaction[5];
      console.log(infotransaction1.slice(0,4) + "..." + infotransaction1.slice(-4));
      this.setState({ contracttransaction1: infotransaction1.slice(0,4) + "..." + infotransaction1.slice(-4)});
    } catch (error) {
      console.error(error);
    }
  }
  
  async handleBuyer() {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      alert("Please install MetaMask to use this feature");
      return;
    }
  
    const contractInstance = new this.state.web3.eth.Contract(contractEscrow, this.state.EscrowAddress);
  
    try {
      const walletaddress = this.state.account;
      const Info = await contractInstance.methods.getTransactionInfo(walletaddress).call({ from: walletaddress });
      const firstValue = Info[0]; // Obtén el primer valor uint256 del array "Info"
      console.log(firstValue);
  
      const transaction = await contractInstance.methods.transactions(firstValue).call({ from: walletaddress });
      const infotransaction = transaction[4];
      console.log(infotransaction.slice(0,4) + "..." + infotransaction.slice(-4));
      this.setState({ contracttransaction: infotransaction.slice(0,4) + "..." + infotransaction.slice(-4)});
    } catch (error) {
      console.error(error);
    }
  }
  
  async handleApprovate() {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      alert("Please install MetaMask to use this feature");
      return;
    }
  
    const contractInstance = new this.state.web3.eth.Contract(contractEscrow, this.state.EscrowAddress);
  
    try {
      const walletaddress = this.state.account;
      const Info = await contractInstance.methods.getTransactionInfo(walletaddress).call({ from: walletaddress });
      const firstValue = Info[0]; // Obtén el primer valor uint256 del array "Info"
      console.log(firstValue);
  
      const transaction = await contractInstance.methods.transactions(firstValue).call({ from: walletaddress });
      const infotransaction2 = transaction[9];
      console.log(infotransaction2);
      if (infotransaction2 === false) {
        this.setState({ contracttransaction2: "Not Approved" });
      } else {
        this.setState({ contracttransaction2: "Approved" });
      }
    } catch (error) {
      console.error(error);
    }
  }
  
  async handleCreateEscrow() {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      alert("Please install MetaMask to use this feature");
      return;
    }
  
    const contractInstance = new this.state.web3.eth.Contract(contractEscrow, this.state.EscrowAddress);
  
    const { numberOfAmount, walletAddressinput, walletAddressinput2 } = this.state;
    const tokenAmount = this.state.numberOfAmount === "" || Number(this.state.numberOfAmount) < 1
      ? "0"
      : this.state.web3.utils.toBN(this.state.web3.utils.toWei(this.state.numberOfAmount)).toString();
  
    const gasPrice = await this.state.web3.eth.getGasPrice();
    const gasLimit = 300000;
  
    const transactionParameters = {
      from: this.state.account,
      gasPrice,
      gas: gasLimit,
    };
  
    try {
      const transactionHash = await contractInstance.methods.createEscrow(walletAddressinput, walletAddressinput2, tokenAmount)
        .send(transactionParameters);
      console.log(`Transaction hash: ${transactionHash}`);
    } catch (error) {
      console.error(error);
    }
  }
  
  async handleApproveEscrowClick() {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      alert("Please install MetaMask to use this feature");
      return;
    }
  
    const contractInstance = new this.state.web3.eth.Contract(contractEscrow, this.state.EscrowAddress);
  
    const gasPrice = await this.state.web3.eth.getGasPrice();
    const gasLimit = 300000;
  
    try {
      const transactionHash = await contractInstance.methods.approve(this.state.numberOfID).send({
        from: this.state.account,
        gasPrice,
        gas: gasLimit
      });
      console.log(`Transaction hash: ${transactionHash}`);
    } catch (error) {
      console.error(error);
    }
  }
  
  async handleinfoSellerClick() {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      alert("Please install MetaMask to use this feature");
      return;
    }
  
    const contractInstance = new this.state.web3.eth.Contract(contractEscrow, this.state.EscrowAddress);
  
    try {
      const walletaddress = this.state.account;
      const transaction = await contractInstance.methods.transactions(this.state.numberOfID).call({ from: walletaddress });
      const infotransactionseller = transaction[5];
      const infotransactionseller1 = transaction[4];
      const infotransactionseller2 = transaction[9];
      const infotransactionseller3 = transaction[1];
      const PriceInEther = this.state.web3.utils.fromWei(infotransactionseller3, 'ether'); // convert wei to ether
      const infotransactionseller4 = transaction[6];
      const FeesInEther = this.state.web3.utils.fromWei(infotransactionseller4, 'ether'); // convert wei to ether
      console.log(infotransactionseller.slice(0,4) + "..." + infotransactionseller.slice(-4), infotransactionseller1.slice(0,4) + "..." + infotransactionseller1.slice(-4), infotransactionseller2, PriceInEther, FeesInEther);
      this.setState({ contracttransactionSeller: infotransactionseller.slice(0,4) + "..." + infotransactionseller.slice(-4), contracttransactionSeller1: infotransactionseller1.slice(0,4) + "..." + infotransactionseller1.slice(-4), contracttransactionSeller2: infotransactionseller2, contracttransactionSeller3: PriceInEther, contracttransactionSeller4: FeesInEther});
      if (infotransactionseller2 === false) {
        this.setState({ contracttransactionSeller2: "Not Approved" });
      } else {
        this.setState({ contracttransactionSeller2: "Approved" });
      }
      alert("Transaction ID: " + this.state.numberOfID + "\n" +
            "Buyer Address: " + infotransactionseller1 + "\n" +
            "Seller Address: " + infotransactionseller + "\n" +
            "Transaction Status: " + infotransactionseller2 + "\n" +
            "Price: " + PriceInEther + " EBT" + "\n" +
            "Transaction fees: " + FeesInEther + " EBT" + "\n");
    } catch (error) {
      console.error(error);
    }
  }
  async handlescrowIdinfo() {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      alert("Please install MetaMask to use this feature");
      return;
    }
  
    const contractInstance = new this.state.web3.eth.Contract(contractEscrow, this.state.EscrowAddress);
  
    try {
      const walletaddress = this.state.account;
      const Info = await contractInstance.methods.getTransactionInfo(walletaddress).call({ from: walletaddress });
      const firstValue = Info[0]; // Obtén el primer valor uint256 del array "Info"
      console.log(firstValue);
      this.setState({ contractInfo: firstValue });
    } catch (error) {
      console.error(error);
    }
  }
async componentDidMount() {
  this._web3Instance = await new Web3Instance().init();
  this.checkConnection();
  if (isConnected()) {
    this.load();
    this.handleGetReferralLink();
    this.checkAccountChange([this.state.account]);
  }
  try {
    const { referralLink } = await this.handleGetReferralLink();
    this.setState({ referralLink });
  } catch (error) {
    console.log(error);
  }

  // Agregar el siguiente código sin borrar los anteriores
  if (typeof window.ethereum !== 'undefined') {
    this.checkAccountChange = this.checkAccountChange.bind(this);
    window.ethereum.on('accountsChanged', this.checkAccountChange);
  }

  if (isConnected()) {
    const amountte = await this.handleGetTransactionAmount();
    this.setState({ amountte });
    const firstValue = await this.handlescrowIdinfo();
    this.setState({ firstValue });
    const infotransaction = await this.handleBuyer();
    this.setState({ infotransaction });
    const infotransaction1 = await this.handleSeller();
    this.setState({ infotransaction1 });
    const infotransaction2 = await this.handleApprovate();
    this.setState({ infotransaction2 });
  }
}

  async checkConnection() {
    if (isConnected()) {
      await this._web3Instance.connect();
      this.load();
    }
  }

  async load() {
    this.setState({
      account: this._web3Instance.getAccount(),
      web3: this._web3Instance.getWeb3(),
      provider: this._web3Instance.getProvider(),
      buttonText: this.displayedAccount(),
    });
  }

  async handleConnectClick() {
    if (!isConnected()) {
      await this._web3Instance.connect();
      this.load();
    }
  }

  async handleDisconnectClick() {
    if (isConnected()) {
    await this._web3Instance.disconnect();
    this.setState({
    account: null,
    web3: null,
    provider: null,
    buttonText: "Connect Wallet"
    });
    }
    }
  displayedAccount() {
    const account = this._web3Instance.getAccount();
    if (account) {
      return account.slice(0, 4) + "..." + account.slice(-4);
    } else {
      return "Connect Wallet";
    }
  }
  resetState() {
    this.setState(this.InitialState)
}
render() {
    return (
      <div>
        <nav className="nav">
          <div className="connect-wallet no-select">
            <button onClick={isConnected() ? this.handleDisconnectClick : this.handleConnectClick}>
              {this.state.buttonText}
            </button>
          </div>
          <Menu />
        </nav>
        <div className="perraputainfame">
  <div className="perraputainfame-track">
    <div className="slide-container">
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
      <p className="slideputita">BUY $EBT</p>
    </div>
  </div>
</div>
        <div className="pitodeperronegro">
        <div className="content">
          <div className="container99 no-select">
            <Fade top>
            <div className="Pantalla99">
            <img src={pantalla} alt="Pantalla" />
            </div>
            </Fade>
            <p className="dailyReward-contract99">You are being attempted to be robbed at the market, don't worry, all of our transactions are secure. Just communicate with the following email "support@epicbeans.io" within 24 hours with your purchase ID and screenshots, and we will resolve it for you.</p>
          <br></br>
          <div className="info99">
                     <div className="balance99">
                       <Slide left>
                     <div className="balance-contract99"><img src={bolsadinero} className="bolsaD" alt="Balance" /> Price: {this.state.contractamountte} EBT</div>
                     </Slide>
                     <Slide right>
                     <div className="rewards-contract99"><img src={vendedor} className="bolsaD" alt="Balance" /> Seller: {this.state.contracttransaction1} </div>
                    </Slide>
                    <Slide left>
                     <div className="rewards-contract99"><img src={comprador} className="bolsaD" alt="Balance" /> Buyer: {this.state.contracttransaction}</div>
                     </Slide>
                     <Slide right>
                     <div className="hasInvested-contract99"><img src={idimgregistro} className="bolsaD" alt="Balance" /> ID: {this.state.contractInfo}</div>
                     </Slide>
                     <Slide left>
                     <div className="bnbTvl-contract99"><img src={idimg} className="bolsaD" alt="Balance" /> Approve: {this.state.contracttransaction2}</div>
                     </Slide>
                     <div className="dailyReward-contract99z12"><img src={caballere} className="bolsaD" alt="Balance" /> A 10% fee is charged per transaction.</div>
                </div>
                  </div>
                  <br></br>
                  <div className="staking99">
               <br></br>
                      <button onClick={this.handleapproveTokens88Click}><img src={castille} className="bolsaD" alt="Balance" /> Approve $EBT</button>
                    </div>
                    <br></br>
                  <div className="form-container99">
                            <input className="input" type="number" min="1" max="100000000" step="1" placeholder="Enter the payment amount in EBT" onChange={this.handleInputChange9} />
                            <Fade bottom>
                            <p className="min-max-text"><img src={information2} className="bolsaD" alt="Balance" /> "Enter the seller's price in EBT.</p>
                            <br></br>
                            </Fade>
                            <input className="input" type="text" maxLength="42" placeholder="Enter your wallet address" onChange={this.handleInputChange12} />
                            <Fade bottom>
                            <p className="min-max-text"><img src={information2} className="bolsaD" alt="Balance" /> Enter your Metamask or Trustwallet Address.</p>
                            <br></br>
                          </Fade>
                            <input className="input" type="text" maxLength="42" placeholder="Enter the seller wallet address" onChange={this.handleInputChange24} />
                            <Fade bottom>
                            <p className="min-max-text"><img src={information2} className="bolsaD" alt="Balance" /> Enter the Address that the seller provided you.</p>
                            <br></br>
                            </Fade>
                            <input className="input" type="number" min="1" step="1" placeholder="Enter the transaction ID" onChange={this.handleInputChange100} />
                            <Fade bottom>
                            <p className="min-max-text"><img src={information2} className="bolsaD" alt="Balance" /> Don't input anything in this format until you're ready to approve the transaction with your purchase ID.</p>
                            </Fade>
                          </div>
                          <div className="button-container99 celbutton">
                            <div className="staking99 celbutton">
                              <button onClick={this.handleCreateEscrow}><img src={armas} className="bolsaD" alt="Balance" /> Purchase</button>
                            </div>
                            <div className="claim99 celbutton">
                              <button onClick={this.handleApproveEscrowClick}><img src={approvater} className="bolsaD" alt="Balance" /> Approve Transaction</button>
                            </div>
                            <div className="withdraw99 celbutton">
                              <button onClick={this.handleinfoSellerClick}><img src={checker} className="bolsaD" alt="Balance" /> CheckID</button>
                              
                            </div>
                            </div>
        </div>
      </div> 
      </div>
      <div className="footer no-select">
          <div className="github">
            <a href="https://github.com" target="_blank">
            <img src={github} className="github" alt="github" />
            </a>
          </div>
          <div className="telegrama">
            <a href="https://t.me/epicbeans" target="_blank">
            <img src={telegram} className="telegrama" alt="telegrama" />
            </a>
          </div>
          <div className="telegrama">
            <a href="https://instagram.com/epicbeansminer?igshid=NGVhN2U2NjQ0Yg==" target="_blank">
            <img src={insta} className="telegrama" alt="telegrama" />
            </a>
          </div>
          <div className="telegrama">
            <a href="https://www.facebook.com/profile.php?id=61553179738387&mibextid=ZbWKwL
" target="_blank">
            <img src={face} className="telegrama" alt="telegrama" />
            </a>
          </div>
          <div className="telegrama">
            <a href="https://opensea.io/collection/epicbeans
" target="_blank">
            <img src={ntf} className="telegrama" alt="telegrama" />
            </a>
          </div>
          <div className="whitherpaper">
            <a href={whitepapper1} target="_blank">
            <img src={whitherpaper} className="whitherpaper" alt="whitherpaper" />
            </a>
          </div>
          <div className="twitter">
            <a href="https://x.com/EPICBEANS_MINER?t=ksBR2eP2O4_Jl_-sHGd2Tg&s=09" target="_blank">
            <img src={twitter} className="twitter" alt="twitter" />
            </a>
          </div>
          <div className="contrato">
            <a href="https://bscscan.com/address/0xb2eecf3771b6541ea03ad128ccaede0d89801ec0" target="_blank">
            <img src={vault} className="contrato" alt="contrato" />
            </a>
          </div>
        </div>
      </div>
    );  
  }
}